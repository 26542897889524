import { DataKiSyokenResponse } from '../../domain/kiSyoken/types';

export const dataKiSyokenData: DataKiSyokenResponse = {
  setting: {
    mainField: 'kiTushoMei',
    fields: [
      'kiTushoMei',
      'donyuDay',
      'usedDaiPrice',
      'ppmKbn',
      'out',
      'daisu',
      'daisuComparisonDifference',
      'kyakuSu',
      'kyakuSuComparisonDifference',
      'kadoRitu',
      'kadoRituComparisonDifference',
    ],
    isFiltering: false,
    dayType: 'all',
    excludeToday: true,
    sort: 'daisuForKiSyoken',
    order: 'desc',
    containsAverage: true,
  },
  data: {
    columns: [
      {
        name: '機種名',
        code: 'kiTushoMei',
        type: 'string',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: false,
      },
      {
        name: '導入日',
        code: 'donyuDay',
        type: 'string',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: false,
      },
      {
        name: '中古機価格',
        code: 'usedDaiPrice',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: false,
      },
      {
        name: 'PPM区分',
        code: 'ppmKbn',
        type: 'string',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: false,
      },
      {
        name: 'アウト',
        code: 'out',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: false,
      },
      {
        name: '台数',
        code: 'daisuForKiSyoken',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
      {
        name: '台数(比較差)',
        code: 'daisuComparisonDifferenceForKiSyoken',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
      {
        name: '客数',
        code: 'kyakuSu',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
      {
        name: '客数(比較差)',
        code: 'kyakuSuComparisonDifference',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
      {
        name: '稼動率',
        code: 'kadoRitu',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
      {
        name: '稼動率(比較差)',
        code: 'kadoRituComparisonDifference',
        type: 'number',
        color: '#000000',
        backgroundColor: null,
        isSisField: false,
        description: null,
        isSyoken: true,
      },
    ],
    hallsOfMarketArea: [
      {
        code: 'hall1',
        name: 'ホール1',
      },
      {
        code: 'hall2',
        name: 'ホール2',
      },
      {
        code: 'hall3',
        name: 'ホール3',
      },
      {
        code: 'hall4',
        name: 'ホール4',
      },
      {
        code: 'hall5',
        name: 'ホール5',
      },
    ],
    rows: [
      {
        queryParameter: {
          name: 'kiList',
          value: null,
        },
        data: [
          {
            value: '平均/合計',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '7,500',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '600',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '900',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,500',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2,500',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '+10',
            color: '#006BAD',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '+30',
            color: '#006BAD',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '+50',
            color: '#006BAD',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024264',
        },
        data: [
          {
            value: '機種1',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2024/12/31',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '4,000,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '金のなる木',
            color: '#000000',
            backgroundColor: '#DAEFC3',
          },
          {
            value: '10,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,500',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '100',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '200',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '300',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '400',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '500',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024271',
        },
        data: [
          {
            value: '機種2',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2024/12/30',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2,000,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '花形',
            color: '#000000',
            backgroundColor: '#A8D3FF',
          },
          {
            value: '20,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,400',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '110',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '190',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '310',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '390',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '400',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024277',
        },
        data: [
          {
            value: '機種3',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2024/12/29',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,000,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '負け犬',
            color: '#000000',
            backgroundColor: '#FFE48F',
          },
          {
            value: '30,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,300',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '120',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '180',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '290',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '410',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '300',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024307',
        },
        data: [
          {
            value: '機種4',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2024/12/28',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '3,000,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '問題児',
            color: '#000000',
            backgroundColor: '#FFB3B3',
          },
          {
            value: '40,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,600',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '130',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '170',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '320',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '380',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '600',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024309',
        },
        data: [
          {
            value: '機種5',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '2024/12/27',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '5,000,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '金のなる木',
            color: '#000000',
            backgroundColor: '#DAEFC3',
          },
          {
            value: '50,000',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '1,700',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '140',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '160',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '280',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '420',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '700',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '350',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '60',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '70',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '80',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '90',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-150',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '10%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '20%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '30%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '40%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '50%',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-10%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-20%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-30%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-40%',
            color: '#C90E00',
            backgroundColor: null,
          },
          {
            value: '-50%',
            color: '#C90E00',
            backgroundColor: null,
          },
        ],
      },
      {
        queryParameter: {
          name: 'kiList',
          value: '024319',
        },
        data: [
          {
            value: '機種6',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
          {
            value: '-',
            color: '#000000',
            backgroundColor: null,
          },
        ],
      },
    ],
  },
};
