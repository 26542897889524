import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { makeKiSyokenDateFromDateRange } from '../domain/kiSyoken/date';
import {
  DataKiSyokenResponse,
  KiSyokenDateRangeParams,
  KiSyokenSearchParams,
} from '../domain/kiSyoken/types';
// import { FavoriteItem } from '../domain/favorites';
import { LoadingState } from '../domain/schemas';

import {
  DataKiSyokenActionCreators,
  DataKiSyokenActionTypes,
  dataKiSyokenDataSelector,
  dataKiSyokenLoadingStateSelector,
  dataKiSyokenResultSelector,
} from '../redux/server/dataKiSyoken';
// import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import {
  ChangeKiSyokenFieldsAction,
  KiSyokenSettingActionCreators,
  KiSyokenSettingActionTypes,
  SearchKiSyokenSettingsAction,
  TriggerKiSyokenSwapFieldsAction,
  kiSyokenColumnsOrderSelector,
  kiSyokenCurrentShuSelector,
} from '../redux/ui/kiSyokenSettings';
import { customSelect } from '../utils/customSelect';
import { recalcColumnOrder } from '../utils/orderedCell';
import { selectShu2SearchCondition } from '../utils/shu';

/**
 * 初回データ取得
 */
function* initFetchKiSyokenDataSaga() {
  const loadingStateKiSyoken: LoadingState = yield select(
    dataKiSyokenLoadingStateSelector
  );

  if (loadingStateKiSyoken !== 'prepare') {
    return;
  }

  yield put(DataKiSyokenActionCreators.fetchDataKiSyokenAction());
  // 初回のレスポンスを初期値として設定する
  yield take(DataKiSyokenActionTypes.FETCH_DATA_KISYOKEN_SUCCESS);
  const dataKiSyoken: DataKiSyokenResponse = yield select(
    dataKiSyokenDataSelector
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenFormCondition(
      dataKiSyoken.setting
    )
  );
}

function* triggerKiSyokenSwapFieldsSaga(
  action: TriggerKiSyokenSwapFieldsAction
) {
  const { draggedId, droppedId } = action.payload;

  const KiSyokenData: DataKiSyokenResponse | undefined = yield select(
    dataKiSyokenDataSelector
  );
  if (KiSyokenData == null) {
    return;
  }

  const currentOrder: string[] = yield select(kiSyokenColumnsOrderSelector);

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? KiSyokenData.data.columns
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    KiSyokenSettingActionCreators.selectKiSyokenColumnsOrderAction(ordered)
  );
}

/**
 * 検索用のクエリの日付を生成する、平日/土日祝のクエリの場合にはデータがAPIへリクエストする
 */
function makeSearchParams(
  dateRangeParams: KiSyokenDateRangeParams,
  searchParams: KiSyokenSearchParams
) {
  const {
    // 日付関連パラメータはdateRangeParamsで追加されるためすべて除外する
    startDate,
    endDate,
    startComparisonDate: _0,
    endComparisonDate: _1,
    ymdList,
    ymdComparisonList,
    ...params
  } = searchParams;

  return {
    ...params,
    ...(dateRangeParams.dateUnit === '自由選択'
      ? { ymdList, ymdComparisonList }
      : makeKiSyokenDateFromDateRange({
          dateRangeParams,
          searchParams,
        })),
  };
}

/**
 * 検索ボタンクリック
 */
function* searchActionSaga(action: SearchKiSyokenSettingsAction) {
  const { params, dateRangeParams } = action.payload;

  // 検索条件をStoreに格納する
  yield put(
    KiSyokenSettingActionCreators.saveKiSyokenSearchParamsAction(params)
  );

  // 選択中の種別・種別グループを取得する
  const currentShu = yield* customSelect(kiSyokenCurrentShuSelector);

  const searchParams: KiSyokenSearchParams = yield call(
    makeSearchParams,
    dateRangeParams,
    params
  );
  // 検索
  yield put(
    DataKiSyokenActionCreators.fetchDataKiSyokenAction({
      ...searchParams,
      ...selectShu2SearchCondition(currentShu),
    })
  );
}

/**
 * 表示項目変更時のリクエスト
 */
function* changeKiSyokenFieldsSaga(action: ChangeKiSyokenFieldsAction) {
  const { notSyokenFields, isSyokenFields } = action.payload;

  const dataSyoken: DataKiSyokenResponse = yield select(
    dataKiSyokenResultSelector
  );

  // notSyokenFieldsとisSyokenFieldsを一つのオブジェクトにまとめる
  const combinedFields: string[] = [...notSyokenFields, ...isSyokenFields];

  yield put(
    DataKiSyokenActionCreators.fetchDataKiSyokenAction({
      ...dataSyoken.setting,
      fields: combinedFields,
    })
  );
}

function* resetSearchActionSaga() {
  // お気に入りを実装する際にお気に入りを考慮する必要がある
  const favoriteId = undefined;

  if (favoriteId == null) {
    yield put(DataKiSyokenActionCreators.renewDataKiSyokenAction());
    yield fork(initFetchKiSyokenDataSaga);
    return;
  }

  // yield put(
  //   DataKiSyokenActionCreators.changeKiSyokenFavoriteAction(favoriteId)
  // );
}

// 検索ボタンクリック時にデータを取得する
function* handleSearchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_KI_SYOKEN_SETTINGS,
    searchActionSaga
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.INIT_KISYOKEN_SETTING,
    initFetchKiSyokenDataSaga
  );
  yield takeEvery(
    KiSyokenSettingActionTypes.CHANGE_KISYOKEN_FIELDS,
    changeKiSyokenFieldsSaga
  );
}
/**
 * お気に入り選択中または未選択時のデフォルト値にする
 */
function* handleSearchResetSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.SEARCH_RESET_KI_SYOKEN_SEARCH_PARAMS,
    resetSearchActionSaga
  );
}

/**
 * 表示項目並び順の変更
 */
function* handleFieldOrderSaga() {
  yield takeEvery(
    KiSyokenSettingActionTypes.TRIGGER_KISYOKEN_SWAP_FIELDS,
    triggerKiSyokenSwapFieldsSaga
  );
}

export function* kiSyokenSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleFieldOrderSaga);
  yield fork(handleSearchSaga);
  yield fork(handleSearchResetSaga);
}
