import { createSelector } from 'reselect';

import { dataKiSyokenFilteredByName } from '../../domain/kiSyoken/dataKiSyokenFilteredByName';
import { dataKiSyokenHiddenByField } from '../../domain/kiSyoken/dataKiSyokenHiddenByField';
import { dataKiSyokenSwapColumnsOrder } from '../../domain/kiSyoken/dataKiSyokenSwapColumnsOrder';
import { sortKiSyoken } from '../../domain/kiSyoken/sortKiSyoken';
import {
  DataKiSyokenResponse,
  DataKiSyokenSearchParams,
} from '../../domain/kiSyoken/types';
import { LoadingState } from '../../domain/schemas';

import { dataKiSyokenData } from '../../mocks/models/dataKiSyoken';
import {
  kiSyokenColumnsOrderSelector,
  kiSyokenNameFilterSelector,
  kiSyokenOrderSelector,
  kiSyokenSelectedTableFilterSelector,
  kiSyokenSortSelector,
} from '../ui/kiSyokenSettings';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_KISYOKEN = 'FETCH_DATA_KISYOKEN' as const;
const FETCH_DATA_KISYOKEN_REQUEST = 'FETCH_DATA_KISYOKEN_REQUEST' as const;
const FETCH_DATA_KISYOKEN_SUCCESS = 'FETCH_DATA_KISYOKEN_SUCCESS' as const;
const RENEW_DATA_KISYOKEN = 'RENEW_DATA_KISYOKEN' as const;

export const DataKiSyokenActionTypes = {
  FETCH_DATA_KISYOKEN,
  FETCH_DATA_KISYOKEN_REQUEST,
  FETCH_DATA_KISYOKEN_SUCCESS,
  RENEW_DATA_KISYOKEN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * dataKiSyokenのデータを取得する
 */
function fetchDataKiSyokenAction(params?: DataKiSyokenSearchParams) {
  return {
    type: FETCH_DATA_KISYOKEN,
    payload: { params },
  };
}

function fetchDataKiSyokenRequestAction() {
  return {
    type: FETCH_DATA_KISYOKEN_REQUEST,
  };
}

function fetchDataKiSyokenSuccessAction(dataKiSyoken: DataKiSyokenResponse) {
  return {
    type: FETCH_DATA_KISYOKEN_SUCCESS,
    payload: { dataKiSyoken },
  };
}

function renewDataKiSyokenAction() {
  return {
    type: RENEW_DATA_KISYOKEN,
  };
}

export const DataKiSyokenActionCreators = {
  fetchDataKiSyokenAction,
  fetchDataKiSyokenRequestAction,
  fetchDataKiSyokenSuccessAction,
  renewDataKiSyokenAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataKiSyokenAction = ReturnType<
  typeof fetchDataKiSyokenAction
>;
type FetchDataKiSyokenRequestAction = ReturnType<
  typeof fetchDataKiSyokenRequestAction
>;
type FetchDataKiSyokenSuccessAction = ReturnType<
  typeof fetchDataKiSyokenSuccessAction
>;
type RenewDataKiSyokenAction = ReturnType<typeof renewDataKiSyokenAction>;

type DataKiSyokenAction =
  | FetchDataKiSyokenAction
  | FetchDataKiSyokenRequestAction
  | FetchDataKiSyokenSuccessAction
  | RenewDataKiSyokenAction;

/* ---------------------------------------------------------------
 * State
 */

type DataKiSyokenState = {
  loadingState: LoadingState;
  dataKiSyoken: DataKiSyokenResponse | undefined;
};

const initialState: DataKiSyokenState = {
  loadingState: 'prepare',
  dataKiSyoken: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] dataの全ての状態を取得する
 */
export function dataKiSyokenSelector(rootState: {
  dataKiSyoken: DataKiSyokenState;
}) {
  return rootState.dataKiSyoken;
}

/**
 * [機種別商圏] dataのローディング状態を取得する
 */
export const dataKiSyokenLoadingStateSelector = createSelector(
  dataKiSyokenSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] dataのデータを取得する
 */
export const dataKiSyokenDataSelector = createSelector(
  dataKiSyokenSelector,
  // ({ dataKiSyoken }) => dataKiSyoken

  // モックデータdataKiSyokenDataを返すように変更
  // ToDo: 本実装時に削除する
  ({ dataKiSyoken }) => dataKiSyokenData
);

/**
 * [機種別商圏] ソート処理によって整形されたデータ (ソートラベル・サブメニュー時)
 *
 */
const dataKiSyokenSortedSelector = createSelector(
  [dataKiSyokenDataSelector, kiSyokenSortSelector, kiSyokenOrderSelector],
  (dataKiSyokenData, kiSyokenSort, kiSyokenOrder) => {
    if (
      kiSyokenSort == null ||
      kiSyokenOrder == null ||
      dataKiSyokenData == null
    ) {
      return dataKiSyokenData;
    }

    const sortedDataKiSyoken = sortKiSyoken(
      dataKiSyokenData,
      kiSyokenOrder,
      kiSyokenSort
    );
    return sortedDataKiSyoken;
  }
);

/**
 * [機種別商圏] フィルターによって行がフィルタリングされたデータ
 */
const dataRowsFilteredSelector = createSelector(
  [
    dataKiSyokenSortedSelector,
    kiSyokenNameFilterSelector,
    // kiSyokenSubMenuNumberFilterSelector,
    // kiSyokenKiTagListSelector,
  ],
  (dataKiSyoken, nameFilter /*, numberFilter, kiTagList*/) => {
    if (dataKiSyoken == null) {
      return dataKiSyoken;
    }

    const filteredByName = dataKiSyokenFilteredByName(dataKiSyoken, nameFilter);

    // ToDo: サブメニュー実装時に実装
    // const filteredByNameAndNumber = dataKiSyokenFilteredByNumber(
    //   filteredByName,
    //   numberFilter
    // );

    // ToDo: 機種タグ実装時に実装
    // const filteredByNameAndNumberAndKiListAndKiTagList =
    //   dataKiSyokenFilteredByKiTagsList(
    //     filteredByNameAndNumberAndKiList,
    //     kiTagList
    //   );

    return filteredByName;
  }
);

/**
 * [機種別償却] 通常は使用しません dataKiSyokenResultSelector  を使用してください
 *
 * 表示項目の新規項目追加時に、columnsOrderの更新が必要になりますが
 * 一度もDnDをしていない場合にはcolumnsOrderが存在していないため実テーブルのデータを使用します
 * そのため通常は使用しません。最終的に表示させたいデータを取得したい場合にはdataKiSyokenResultSelectorを使用してください
 */
export const dataColumnsSwappedSelector = createSelector(
  [dataRowsFilteredSelector, kiSyokenColumnsOrderSelector],
  (filteredDataKiSyoken, columnsOrder) => {
    if (filteredDataKiSyoken == null) {
      return filteredDataKiSyoken;
    }

    const swappedFieldsOrder = dataKiSyokenSwapColumnsOrder(
      filteredDataKiSyoken,
      columnsOrder
    );

    return swappedFieldsOrder;
  }
);

/**
 * [機種別償却] フィルターやスワップ、列非表示によって整形されたデータ
 */
export const dataKiSyokenResultSelector = createSelector(
  [dataColumnsSwappedSelector, kiSyokenSelectedTableFilterSelector],
  (swappedDataKiSyoken, fieldsFilter) => {
    if (swappedDataKiSyoken == null) {
      return swappedDataKiSyoken;
    }

    const hiddenByField = dataKiSyokenHiddenByField(
      swappedDataKiSyoken,
      fieldsFilter
    );

    return hiddenByField;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataKiSyokenReducer(
  state = initialState,
  action: DataKiSyokenAction
): DataKiSyokenState {
  switch (action.type) {
    case FETCH_DATA_KISYOKEN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_KISYOKEN_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataKiSyoken: action.payload.dataKiSyoken,
      };
    case RENEW_DATA_KISYOKEN:
      return initialState;
    default:
      return state;
  }
}
