import { DataKiSyokenResponse } from './types';

export const dataKiSyokenHiddenByField = (
  dataKiSyoken: DataKiSyokenResponse,
  fields: string[] | undefined
): DataKiSyokenResponse => {
  if (fields == null) {
    return dataKiSyoken;
  }

  const hiddenIndexes = fields.map((field) => {
    return dataKiSyoken.data.columns.findIndex(
      (column) => column.code === field
    );
  });

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      columns: dataKiSyoken.data.columns.filter(
        (_, i) => !hiddenIndexes.includes(i)
      ),
      rows: dataKiSyoken.data.rows.map((row) => {
        return {
          ...row,
          data: row.data.filter((_, i) => !hiddenIndexes.includes(i)),
        };
      }),
    },
  };
};
